import React from 'react';
import { useIntl } from '@itsa.io/web3utils';
import useStyles from 'styles/pages/FundAHome';

const FundAHomePage = () => {
	const { t } = useIntl();
	const classes = useStyles();

	return (
		<div className={classes.root}>{t('page.fund_a_home.description')}</div>
	);
};

export default FundAHomePage;
